// pages
import Index1 from "../pages/Startuplanding/Index1"
const routes = [
    // Startup-Landing
    { path: "/startup-landing", component: <Index1 /> },

    { path: "/", component: <Index1 /> },

]

export { routes };