import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Counter from '../../components/Counter';
import Testimonial from '../../components/Testimonial';
import Contact from '../../components/Contact';
import FooterSection from '../../layout/Footer';
import Pricing from '../../components/Pricing';

//images
//features
import feature8 from "../../assets/images/features/feature-8.svg"
import feature9 from "../../assets/images/features/feature-9.jpg"
import feature10 from "../../assets/images/features/feature-10.svg"
import feature11 from "../../assets/images/features/feature-11.png"

//team
import team1 from "../../assets/images/team/team-1.jpg"
import team2 from "../../assets/images/team/team-2.jpg"
import team3 from "../../assets/images/team/team-3.jpg"
import team4 from "../../assets/images/team/team-4.jpg"

interface Data {
    id: number; head: string; icon: string; txt:string;
}

interface Feature {
    id: number; title: string;
}

interface TeamMembers {
    name: string; role: string; imageSrc: string;
}

const data: Data[] = [
    { id: 1, head: "By The Engineers", icon: "pe-7s-rocket" , txt: "All the trainings are conducted by experienced engineers, working with worlds leading MNCs."},
    { id: 2, head: "To The Engineers", icon: "pe-7s-vector" , txt: "With intention to provide the detailed understanding of tools and techniques used in the real time."},
    { id: 3, head: "For The Engineers", icon: "pe-7s-edit" , txt: "Hence every trainee can be as productive as possible for the team since day one."},
]

const feature: Feature[] = [
    { id: 1, title: "COUNTDOWN TIMER" },
    { id: 2, title: "PARALLAX" },
    { id: 3, title: "GOOGLE MAPS" },
]

const teamMembers: TeamMembers[] = [    
    { name: 'Krishna Reddy SV', role: 'Founder & Chief Trainer', imageSrc: team1, },
    { name: 'Druva Kumar K', role: 'Senior Python Trainer', imageSrc: team2, },
    { name: 'Pavani D', role: 'UI & UX Trainer', imageSrc: team3, },
    { name: 'Venkat B', role: 'AWS & Cloud Services Trainer', imageSrc: team4, },
    { name: 'Siva', role: 'AI & ML Trainer', imageSrc: team4, },
];

const Section1 = () => {
    return (
        <React.Fragment>
            {/* About */}
            <section className="section startup-about" id="about">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div>
                                <p className="startup-about-heading text-center text-muted line-height_1_8">We are a training company backed by decades of engineering experience, knowing precisely what is expected by industry, we can work to make you <span style={{"color":"#800080"}}>THE WANTED...!</span></p>                                
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-4">
                        {
                            (data || [])?.map((item: Data, key: number) => (
                                <Col lg={4} key={key}>
                                    <div className="startup-about-box mt-4">
                                        <div className="startup-about-icon float-start">
                                            <i className={item.icon}></i>
                                        </div>
                                        <div className="startup-about-content ps-4 pt-2">
                                            <h4 className="f-15 letter-spacing_2 text-uppercase purple-txt">{item.head}</h4>
                                            <p className="text-muted mt-3">{item.txt}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-5 pt-4">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase purple-txt">Why Skillyheads</h2>
                                <p className="heading-title-desc text-muted mt-4">
                                    <ul style={{listStyleType: 'none'}}>
                                        <li>We just don't bloat for the sake, we teach what is being used in industry.</li>
                                        <li>Our content is fine tuned by various engineers and managers.</li>
                                        <li>Nothing theoritically tought but everything is practically shown.</li>
                                    </ul>
                                </p>                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Features */}

            <section className="section" id="courses">
                <Container>
                    <Row className="align-items-center mt-5 pt-3">
                        <Col md={7}>
                            <div className="startup-features-box mt-4">
                                <div className="startup-features-icon">
                                    <i className="pe-7s-way"></i>
                                </div>
                                <h4 className="mt-3 f-15 text-uppercase letter-spacing_2">Java Fullstack</h4>
                                <p className="startup-features-desc text-muted mt-4" style={{textAlign:'justify'}}>Java, being leading technology in banking, insurance, logistics, transport, retail and many other domains, it absolutly absorbs most number of job seekers worldwide. It is going to do so for decades to come.  </p>
                                <p className="startup-features-desc text-muted mt-4">Its vast, you need an experienced guide to cut through clutter. We are there. Lets Brew.</p>
                                <div className="mt-4">
                                    {/* <Link to="#" className="btn btn-md btn-dark-custom">Learn More <i className="mdi mdi-arrow-right"></i></Link> */}
                                </div>
                            </div>
                        </Col>

                        <Col md={5}>
                            <div className="startup-features-img mt-4">
                                <img src={feature8} className="img-fluid mx-auto d-block" alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center mt-5 pt-4">
                        <Col md={7}>
                            <div className="startup-features-box mt-4">
                                <div className="startup-features-icon">
                                    <i className="pe-7s-way"></i>
                                </div>
                                <h4 className="mt-3 f-15 text-uppercase letter-spacing_2">Python Fullstack</h4>
                                <p className="startup-features-desc text-muted mt-4" style={{textAlign:'justify'}}>Python, along with its dominance in artificial intelligence, data science and machine learning; python is also popular among web development, scientific computing, and automation hence it is pulling exponential number of tech enthusiasts worldwide. Its versatility and simplicity ensure its pivotal role in technological innovation happening as you scroll through.</p>
                                <p className="startup-features-desc text-muted mt-4">We will show you the right way to tame it like PRO.</p>
                                <div className="mt-4">
                                    {/* <Link to="#" className="btn btn-md btn-dark-custom">Learn More <i className="mdi mdi-arrow-right"></i></Link> */}
                                </div>
                            </div>
                        </Col>

                        <Col md={5}>
                            <div className="startup-features-img">
                                <img src={feature10} className="img-fluid mx-auto d-block" alt="" width={200}/>
                            </div>
                        </Col>
                    </Row>


                    <Row className="align-items-center mt-5 pt-4">
                        <Col md={7}>
                            <div className="startup-features-box mt-4">
                                <div className="startup-features-icon">
                                    <i className="pe-7s-way"></i>
                                </div>
                                <h4 className="mt-3 f-15 text-uppercase letter-spacing_2">AI & ML</h4>
                                <p className="startup-features-desc text-muted mt-4" style={{textAlign:'justify'}}>Artificial Intelligence and Machine Learning are revolutionizing industries worldwide, transforming healthcare, finance, technology, automotive, and scientific research. These groundbreaking technologies are reshaping human capabilities, driving unprecedented innovation, and creating limitless opportunities for those who master their intricate domains.</p>
                                <p className="startup-features-desc text-muted mt-4"> We will nurture your natural intelligence to get the grasp on artificial intelligence</p>
                                <div className="mt-4">
                                    {/* <Link to="#" className="btn btn-md btn-dark-custom">Learn More <i className="mdi mdi-arrow-right"></i></Link> */}
                                </div>
                            </div>
                        </Col>

                        <Col md={5}>
                            <div className="startup-features-img">
                                <img src={feature11} className="img-fluid mx-auto d-block" alt="" width={200}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Counter */}
            <Counter />


            {/* Team */}
            <section className="section" id="team">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                                    Our Team
                                </h2>
                                <p className="heading-title-desc text-muted mt-4">
                                    Every member of our trainers team is at least 10years experienced and dedicated to make every student we train to be usuable from day one in the team. 
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <div className="row mt-5 pt-3">
                        {(teamMembers || [])?.map((member: TeamMembers, index: number) => (
                            <Col lg={3} key={index}>
                                <div className="startup-team-box text-center mt-4">                      
                                    <div className="startup-team-content p-4 text-center">
                                        <h3 className="f-15 text-uppercase letter-spacing_2">
                                            {member.name}
                                        </h3>
                                        <p className="text-muted f-13 mb-0">{member.role}</p>

                                        <div className="mt-3">
                                            <ul className="list-inline startup-team-social mb-0">
                                                <li className="list-inline-item">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-facebook"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <Link to="#" className="rounded-circle">
                                                        <i className="mdi mdi-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </div>
                </Container>
            </section>

            <hr />

            {/* Contact */}
            <Contact />

            {/* Footer */}
            <FooterSection />

        </React.Fragment>
    )
}

export default Section1