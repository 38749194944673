import React, { useState } from 'react'
import { Alert, Col, Container, Form, Row } from 'react-bootstrap'

const Contact: React.FC = () => {

  const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    // Access form field values
    const name = formData.get('name');
    const email = formData.get('email');
    const comments = formData.get('comments');

    const validationError = validateForm(name, email, comments);
    if (validationError) {
      setErrorMsg(validationError);
      return;
    }
    form.reset();
    setErrorMsg('');
  };

  const validateForm = (name: any, email: any, comments: any) => {
    if (!name || name.trim() === '') {
      return 'Please enter your name.';
    }
    if (!email || email.trim() === '') {
      return 'Please enter your email.';
    }
    if (!comments || comments.trim() === '') {
      return 'Please enter your comments.';
    }
    return '';
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  Get In Touch
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  We are pleased to be in touch with you.  
                </p>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-4">
            <Col lg={6}>
              <div className="startup-map mt-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241.4961356497427!2d79.9736650102521!3d14.430726766556566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4cf325175e796f%3A0x11553192ab58d31e!2sCXJF%2B7C8%2C%20Magunta%20Layout%2C%20Nellore%2C%20Andhra%20Pradesh%20524003!5e0!3m2!1sen!2sin!4v1732110317835!5m2!1sen!2sin"
                  height="350"
                  style={{ border: 0, width: '100%' }}
                  allowFullScreen
                  title="Google Map"
                ></iframe>
              </div>
            </Col>

            <Col lg={6}>
              <div className="custom-form mt-3">
                <form method="post" onSubmit={handleSubmit} className="contact-form" name="myForm" id="myForm">
                  {/* <div id="error-msg"></div> */}                  
                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-3">
                        Skillyheads Pvt Ltd,<br/>
                        Beside Teakwood Furniture,<br/>
                        3rd Main Road,Magunta Layout,<br/>
                        Nellore - 524003.<br/>
                      </div>
                    </Col>                    
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <div className="form-group mt-3">
                        +91 81 21 31 7331, +91 91505 91595
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <div className="mt-3">                        
                        contact@skillyheads.com<br/>
                        hr@skillyheads.com<br/>
                        info@skillyheads.com<br/>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </div>
        </Container>
      </section>

    </React.Fragment>
  )
}

export default Contact;